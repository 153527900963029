<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Catálogo de modelos de motocicletas</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord()">Añadir</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload()" />
						</div>
					</div>

					<a-table :columns="columns" :dataSource="motorcycleModelList" rowKey="motorcycle_model_id">
						<div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px">
							<a-input
								v-ant-ref="(c) => (searchInput = c)"
								placeholder="Búsqueda"
								:value="selectedKeys[0]"
								style="width: 188px; margin-bottom: 8px; display: block"
								@change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
								@pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
							/>
							<a-button type="primary" icon="search" size="small" style="width: 90px; margin-right: 8px" @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"> Buscar </a-button>
							<a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"> Limpiar </a-button>
						</div>
						<a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
						<template slot="customRender" slot-scope="text, record, index, column">
							<span v-if="searchText && searchedColumn === column.dataIndex">
								<template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
									<mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{ fragment }}</mark>
									<template v-else>{{ fragment }}</template>
								</template>
							</span>
							<template v-else>
								{{ text }}
							</template>
						</template>
						<span slot="status" slot-scope="record">
							<a-tag color="red" v-if="record.active == 0">Inactivo</a-tag>
							<a-tag color="green" v-if="record.active == 1">Activo</a-tag>
						</span>
						<span slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record)" />
							<a-button class="ml5" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="onToggleRecord(record.motorcycle_model_id, record.active)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
		<insertOrUpdateMotorcycleModel :visible="modal.visible" :title="modal.title" :payload="modal.payload" @closeModal="onCloseModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import insertOrUpdateMotorcycleModel from '@/components/insertOrUpdateMotorcycleModel'

export default {
	name: 'motorcycleModel',
	components: {
		insertOrUpdateMotorcycleModel,
	},
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'motorcycle_model_id',
					align: 'center',
				},
				{
					title: 'Marca',
					dataIndex: 'brand_name',
					key: 'brand_name',
					align: 'center',
					scopedSlots: {
						filterDropdown: 'filterDropdown',
						filterIcon: 'filterIcon',
						customRender: 'customRender',
					},
					onFilter: (value, record) => record.brand_name.toLowerCase().includes(value.toLowerCase()),
					onFilterDropdownVisibleChange: (visible) => {
						if (visible) {
							setTimeout(() => {
								this.searchInput.focus()
							}, 0)
						}
					},
				},
				{
					title: 'Modelo',
					dataIndex: 'model_name',
					key: 'model_name',
					align: 'center',
					scopedSlots: {
						filterDropdown: 'filterDropdown',
						filterIcon: 'filterIcon',
						customRender: 'customRender',
					},
					onFilter: (value, record) => record.model_name.toLowerCase().includes(value.toLowerCase()),
					onFilterDropdownVisibleChange: (visible) => {
						if (visible) {
							setTimeout(() => {
								this.searchInput.focus()
							}, 0)
						}
					},
				},
				{
					title: 'Estatus',
					scopedSlots: { customRender: 'status' },
					align: 'center',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'right',
				},
			],
			modal: {
				visible: false,
				title: '',
				payload: {},
			},
			searchText: '',
			searchInput: null,
			searchedColumn: '',
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('motorcycleModels', ['motorcycleModelList']),
		spinnerLoaderLabel() {
			return this.$store.state.motorcycleModels.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.motorcycleModels.spinnerLoader
		},
		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
	},
	beforeMount() {},
	mounted() {
		if (this.motorcycleModelList.length == 0) {
			this.getModels()
		}
	},
	methods: {
		getModels() {
			this.$store.dispatch('motorcycleModels/GET')
		},
		onReload() {
			this.getModels()
		},
		onAddRecord() {
			this.modal = {
				visible: true,
				title: 'Añadir registro de un modelo de motocicleta',
				payload: {},
			}
		},
		onEditRecord(record) {
			console.log('onEditRecord-->', record)
			this.modal = {
				visible: true,
				title: 'Editar registro de un modelo de motocicleta',
				payload: _.cloneDeep(record),
			}
		},
		onToggleRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					if (status) {
						this.$store.dispatch('motorcycleModels/DISABLE', id)
					} else {
						this.$store.dispatch('motorcycleModels/ENABLE', id)
					}
				}
			})
		},
		onCloseModal() {
			this.modal.visible = false
		},
		handleSearch(selectedKeys, confirm, dataIndex) {
			confirm()
			this.searchText = selectedKeys[0]
			this.searchedColumn = dataIndex
		},
		handleReset(clearFilters) {
			clearFilters()
			this.searchText = ''
		},
	},
}
</script>
